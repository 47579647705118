import React from "react";
import { Box } from "@mui/material";

const Container = ({ ...props }) => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(to left top, #9FA4C4 0%, #BDD4E7 80%)",
          minHeight: "100vh",
          width: "100%",
          padding: "1rem",
        },
        { ...props.sx },
      ]}
    >
      {props.children}
    </Box>
  );
};

export default Container;
