import { Grow, Stack, styled, Typography } from "@mui/material";
import React from "react";
import GlassCard from "../GlassCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const WorkExperience = () => {
  const jobs = [
    {
      id: "1",
      title: "Artificial Intelligence Researcher",
      company: "WIMTACH",
      location: "Toronto, CA.",
      date: "Jan to Jun 2022",
      description:
        "Part of the developer team in charge of building a cloud and local solution for Face Emotion Recognition using Python and Amazon Web Services (AWS), such as: AWS S3, AWS Rekognition, and AWS MediaConvert. We engineered and code the full pipeline for the project, starting from: data collection, data annotation, data preprocessing, model building, model training, model testing, model fine tuning and model deployment.",
    },
    {
      id: "2",
      title: "Full Stack Developer",
      company: "WIMTACH",
      location: "Toronto, CA.",
      date: "Apr 2021 to Jun 2022",
      description:
        "On this period, me and my development team were assigned a project to create a mobile app as a minimum viable product (MVP) for a Canadian Startup. My responsibility was to design the UI/UX, create the API (business logic), deploy the API on the server, test the app and finally publish to the stores. We used MongoDB, Express, React Native and NodeJS (MERN) stack to complete this project.",
    },
    {
      id: "3",
      title: "Business Manager",
      company: "Inveriones Yepes Carrera",
      location: "Medellin, COL.",
      date: "Jan 2017 to Apr 2019",
      description:
        "This is a small-medium size enterprise with a traditional livestock and genetic center in Colombia since 1960 ś . My responsibility in this company was the good management of accounting and decision making based on the company's income statements as well as the innovation and implementation of new technologies to improve the company ś processes and productivity.",
    },
    {
      id: "4",
      title: "Assistant at Fixed Income Table, Asset Management Department",
      company: "Bancolombia",
      location: "Medellin, COL.",
      date: "Jun to Dec 2016",
      description:
        "Responsible for different tasks based on the administration and management of financial assets, mainly fixed income instruments such as securities, government bonds or low volatility assets, in order to support the head manager of the hedge fund. In this position, I developed ways to facilitate the operational tasks of the people working in this department by automating multiple tasks, to analyze the competition and new investment opportunities, to evaluate the current investments of the fund and to keep the information organized and safe.",
    },
  ];
  const ModifiedGlassCard = styled(GlassCard)({
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  });

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
      textAlign="center"
    >
      {jobs.map((job, i) => {
        return (
          <Grow key={job.id} in={true} timeout={500 * (i + 1)}>
            <ModifiedGlassCard>
              <Stack direction="column" alignItems="center" spacing={2}>
                <Typography variant="h5" component="h2">
                  {job.title}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  alignSelf="center"
                >
                  <ApartmentIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {job.company}
                  </Typography>
                  <LocationOnIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {job.location}
                  </Typography>
                  <AccessTimeIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {job.date}
                  </Typography>
                </Stack>
                <Typography
                  variant="p"
                  component="p"
                  textAlign="center"
                  fontWeight={200}
                  letterSpacing={0.2}
                  lineHeight={1.2}
                >
                  {job.description}
                </Typography>
              </Stack>
            </ModifiedGlassCard>
          </Grow>
        );
      })}
    </Stack>
  );
};

export default WorkExperience;
