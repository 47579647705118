import React from "react";
import { Box } from "@mui/material";

const GlassCard = React.forwardRef((props, ref) => (
  <Box
    {...props}
    ref={ref}
    sx={[
      {
        display: "flex",
        flexWrap: "wrap",
        overflow: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        }, // for safari, chrome and opera
        scrollbarWidth: "none", // for firefox
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.35)",
        minHeight: 0,
        height: "auto",
        maxHeight: "95vh",
        minWidth: 0,
        maxWidth: "1200px",
        backdropFilter: "blur(3px)",
        borderRadius: "2rem",
        padding: 5,
        zIndex: 3,
        boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
      }, // default styles
      { ...props.sx }, // new styles: modify styles if children have new styles
    ]}
  >
    {props.children}
  </Box>
));
export default GlassCard;
