import React from "react";
import { Box, Zoom } from "@mui/material";
import GlassButton from "../GlassButton";
import InstagramIcon from "@mui/icons-material/Instagram";

const InstagramButton = React.forwardRef((props, ref) => (
  <Box {...props} ref={ref}>
    <Zoom in={props.showButton} timeout={200}>
      {
        <GlassButton
          href={props.href}
          target={props.target} // open link in new window
          sx={{
            minHeight: 0,
            minWidth: 0,
            padding: 1,
            ":hover": {
              color: "white",
              transitionProperty: "all",
              transitionDuration: "250ms",
              transform: "scale(1.05)",
              backgroundImage:
                "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
            },
          }}
        >
          <InstagramIcon />
        </GlassButton>
      }
    </Zoom>
  </Box>
));

export default InstagramButton;
