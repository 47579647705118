import { Grow, Stack, styled, Typography } from "@mui/material";
import React from "react";
import GlassCard from "../GlassCard";
import DoneIcon from "@mui/icons-material/Done";

const Skills = () => {
  const ModifiedTypography = styled(Typography)({
    variant: "p",
    component: "p",
    ":hover": {
      fontWeight: "bold",
    },
  });

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <Grow in={true} timeout={800}>
        <GlassCard>
          <Stack direction="row" spacing={5}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Spanish - Native</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>English - Fluent</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>MERN Full Stack</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>JavaScript</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>AWS S3</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>AWS Rekognition</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Python</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>ReactJS</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>React Native</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Scikit-Learn</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Tensorflow</ModifiedTypography>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>SQL</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>NoSQL</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>MongoDB</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>ExpressJS</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>HTML 5</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>CSS</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>NodeJS</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Inventory Management</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Financial Analyst</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Customer Relations</ModifiedTypography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <DoneIcon />
                <ModifiedTypography>Microsoft Office</ModifiedTypography>
              </Stack>
            </Stack>
          </Stack>
        </GlassCard>
      </Grow>
    </Stack>
  );
};

export default Skills;
