import { Grow, Stack, styled, Typography } from "@mui/material";
import React from "react";
import GlassCard from "../GlassCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";

const Studies = () => {
  const academicTitles = [
    {
      id: "1",
      program: "Software Engineering Technology - Artificial Intelligence",
      academicTitle: "Advanced Diploma",
      institution: "Centennial College",
      location: "Toronto, CA.",
      date: "Sep 2019 to Apr 2022",
    },
    {
      id: "2",
      program: "Financial Engineering",
      academicTitle: "Bachelors of Engineering",
      institution: "EIA University",
      location: "Medellin, COL.",
      date: "Jan 2011 to Dec 2016",
    },
    {
      id: "3",
      program: "Project Management",
      academicTitle: "Diploma",
      institution: "EAFIT University",
      location: "Medellin, COL.",
      date: "Aug to Dec 2016",
    },
  ];
  const ModifiedGlassCard = styled(GlassCard)({
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  });

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      {academicTitles.map((title, i) => {
        return (
          <Grow key={title.id} in={true} timeout={500 * (i + 1)}>
            <ModifiedGlassCard>
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                textAlign="center"
              >
                <Typography variant="h5" component="h2">
                  {title.program}
                </Typography>

                <Stack direction="row" spacing={2}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <HistoryEduIcon />
                      <Typography variant="p" component="p" textAlign="left">
                        {title.academicTitle}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <SchoolIcon />
                      <Typography variant="p" component="p" textAlign="left">
                        {title.institution}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <LocationOnIcon />
                      <Typography variant="p" component="p" textAlign="left">
                        {title.location}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AccessTimeIcon />
                      <Typography variant="p" component="p" textAlign="left">
                        {title.date}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </ModifiedGlassCard>
          </Grow>
        );
      })}
    </Stack>
  );
};

export default Studies;
