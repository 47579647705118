import React from "react";
import { Box, Button } from "@mui/material";

const GlassButton = React.forwardRef((props, ref) => (
  <Box {...props} ref={ref} sx={{ minWidth: 0, minHeight: 0 }}>
    <Button
      href={props.href}
      target={props.target}
      onClick={props.clickHandler || null}
      sx={[
        {
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(255, 255, 255, 0.3)",
          height: "auto",
          width: "auto",
          borderRadius: "2rem",
          paddingX: 2,
          paddingY: 1,
          zIndex: 3,
          // is pressed
          fontSize: props.isPressed && "1rem",
          backgroundImage:
            props.isPressed &&
            "linear-gradient(to right bottom, #6782B4 , #B1BFD8 )",
          color: props.isPressed ? "white" : "inherit",
          transform: props.isPressed && "scale(0.95)",
          // hover
          ":hover": !props.isPressed && {
            color: "white",
            transitionProperty: "all",
            transitionDuration: "250ms",
            transform: "scale(1.1)",
            backgroundImage:
              "linear-gradient(to right bottom, #6782B4 , #B1BFD8 )",
          },
        }, // default styles
        { ...props.sx }, // new styles: modify styles if children have new styles
      ]}
    >
      {props.children}
    </Button>
  </Box>
));

export default GlassButton;
