import { Grow, Stack, styled, Typography } from "@mui/material";
import React from "react";
import GlassCard from "../GlassCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Awards = () => {
  const awards = [
    {
      id: "1",
      title: "Toronto Zoo Hackathon",
      company: "Toronto Zoo",
      location: "Toronto, CA.",
      date: "May 2021",
      description:
        "Our team won the second place by proposing an innovative solution to capture customers attention at the Toronto Zoo, by implementing a new app design and a 3D game proposal.",
    },
    {
      id: "2",
      title: "Petnerup Hackathon",
      company: "Petnerup",
      location: "Toronto, CA.",
      date: "Apr 2021",
      description:
        "Our team won the first place by proposing the best business idea and best UI/UX design that meets the customer needs.",
    },
    {
      id: "3",
      title: "Innovation on Labor Security",
      company: "Conconcreto",
      location: "Medellin, COL.",
      date: "Jul 2016",
      description:
        "Our team won the first place by proposing an innovative solution to be able to prevent, predict and control accidents in construction sites, using a mobile or web application for the daily use of operators and construction managers.",
    },
  ];
  const ModifiedGlassCard = styled(GlassCard)({
    maxWidth: "800px",
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  });

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      {awards.map((award, i) => {
        return (
          <Grow key={award.id} in={true} timeout={500 * (i + 1)}>
            <ModifiedGlassCard>
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                textAlign="center"
              >
                <Typography variant="h5" component="h2">
                  {award.title}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  alignSelf="center"
                >
                  <ApartmentIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {award.company}
                  </Typography>
                  <LocationOnIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {award.location}
                  </Typography>
                  <AccessTimeIcon />
                  <Typography variant="p" component="p" textAlign="left">
                    {award.date}
                  </Typography>
                </Stack>
                <Typography
                  variant="p"
                  component="p"
                  textAlign="center"
                  fontWeight={200}
                  letterSpacing={0.2}
                  lineHeight={1.2}
                >
                  {award.description}
                </Typography>
              </Stack>
            </ModifiedGlassCard>
          </Grow>
        );
      })}
    </Stack>
  );
};

export default Awards;
