import React from "react";
import Container from "../components/Container";
import { Stack, Typography, Zoom, styled, Grow, Box } from "@mui/material";
import GlassButton from "../components/GlassButton";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { useState } from "react";
import GlassCard from "../components/GlassCard";

const Portfolio = () => {
  const [showFilter, setShowFilter] = useState("Web App");

  const projects = [
    {
      id: "1",
      title: "Social media app",
      type: "Mobile App",
      description:
        "Social media app focus on pets where users can interact with other users by posting pictures, videos, comments and much more. This is a Startup idea for people to involve more with their pets.\n\nFunctionalities:\n\nCreate user, follower/following capabilities, image and video posting, instant messaging (chat), endorsing system.\n\nPD: This app may not be able for the public because the Startup isn't on market yet. However, take a look at the link below to see their website and get an idea of the app",
      technologies: ["React", "MongoDB", "ExpressJS", "NodeJS"],
      link: "https://www.petnerup.com/",
    },
    {
      id: "2",
      title: "Blog posting app",
      type: "Web App",
      description:
        "Web app thats simulates a blog website where you can post new campgrounds and share your ratings with others.\n\nFunctionalities:\n\nCreate user, image posting, ranking system.",
      technologies: ["JavaScript", "CSS", "HTML", "MongoDB", "NodeJS"],
      link: "https://anotheryelpcamp.herokuapp.com/",
    },
    {
      id: "7",
      title: "MUI Facebook",
      type: "Web App",
      description: "Web App created to improve ReactJS and MUI skill.",
      technologies: ["React"],
      link: "https://muifacebook.netlify.app/",
    },
    {
      id: "3",
      title: "User authentication app",
      type: "Web App",
      description:
        "Web App created to improve nodeJs, ExpressJS and JavaScript skills\n\nFunctionalities:\n\nCreate user and save user credentials.",
      technologies: ["JavaScript", "CSS", "HTML", "ExpressJS", "NodeJS"],
      link: "https://learntwebapp.herokuapp.com/",
    },
    {
      id: "9",
      title: "Food ordering app",
      type: "Web App",
      description: "Web App created to improve ReactJS skills.",
      technologies: ["React"],
      link: "https://foodorderingprototype.netlify.app/",
    },
    {
      id: "4",
      title: "Budget app",
      type: "Website",
      description: "Web App created to improve JavaScript, HTML and CSS skills",
      technologies: ["JavaScript", "CSS", "HTML"],
      link: "https://budgetappprototype.netlify.app/",
    },
    {
      id: "5",
      title: "Dice Game",
      type: "Website",
      description: "Web App created to improve JavaScript, HTML and CSS skills",
      technologies: ["JavaScript", "CSS", "HTML"],
      link: "https://dicegameprototype.netlify.app/",
    },
    {
      id: "11",
      title: "Website design 1",
      type: "Website",
      description:
        "Website created on the PotLuck Hackaton by our team to demonstrate web design skills.",
      technologies: ["JavaScript", "CSS", "HTML"],
      link: "https://websitedesign1syc.netlify.app/",
    },
    {
      id: "10",
      title: "Website design 2",
      type: "Website",
      description: "Website created to improve JavaScript, HTML and CSS skills",
      technologies: ["JavaScript", "CSS", "HTML"],
      link: "https://websitedesign2syc.netlify.app/",
    },
    {
      id: "6",
      title: "More Coming soon...",
      type: "Machine Learning",
      description: "",
      technologies: [],
      link: "#",
    },
  ];

  const ModifiedGlassCard = styled(GlassCard)({
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  });

  return (
    <Container sx={{ alignItems: "flex-start" }}>
      <Zoom in={true} timeout={200}>
        <Stack direction="column" spacing={3}>
          <Typography variant="h3" component="h1" sx={{ alignSelf: "center" }}>
            Portfolio
          </Typography>

          {/* about me buttons */}
          <Stack
            direction="row"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            spacing={1}
          >
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              <GlassButton>
                <HomeIcon />
              </GlassButton>
            </Link>
            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showFilter === "Web App"}
              clickHandler={() => {
                setShowFilter("Web App");
              }}
            >
              <Typography variant="p" component="p">
                Web Apps
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showFilter === "Website"}
              clickHandler={() => {
                setShowFilter("Website");
              }}
            >
              <Typography variant="p" component="p">
                Websites
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showFilter === "Mobile App"}
              clickHandler={() => {
                setShowFilter("Mobile App");
              }}
            >
              <Typography variant="p" component="p">
                Mobiles Apps
              </Typography>
            </GlassButton>

            <GlassButton
              sx={{ margin: 1 }}
              isPressed={showFilter === "Machine Learning"}
              clickHandler={() => {
                setShowFilter("Machine Learning");
              }}
            >
              <Typography variant="p" component="p">
                Machine Learning
              </Typography>
            </GlassButton>
          </Stack>

          {projects
            .filter((project) => project.type === showFilter)
            .map((project, i) => {
              return (
                <Grow key={project.id} in={true} timeout={500 * (i + 1)}>
                  <ModifiedGlassCard
                    sx={{
                      padding: 3,
                      justifyContent: "flex-start",
                      maxWidth: "800px",
                    }}
                  >
                    <Stack direction="column" width="100%">
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6" component="h2">
                          {project.title}
                        </Typography>

                        <GlassButton href={project.link}>
                          Visit Project
                        </GlassButton>
                      </Stack>

                      <Typography
                        variant="p"
                        component="p"
                        fontWeight={500}
                        marginTop={2}
                      >
                        Description
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        textAlign="justify"
                        fontWeight={200}
                        letterSpacing={0.2}
                        lineHeight={1.2}
                        marginBottom={2}
                      >
                        {project.description}
                      </Typography>

                      <Typography
                        variant="p"
                        component="p"
                        fontWeight={500}
                        marginBottom={1}
                      >
                        Technologies
                      </Typography>

                      <Stack direction="row" spacing={2} flexWrap="wrap">
                        {project.technologies.map((tech) => {
                          return (
                            <Box
                              key={tech}
                              sx={{ height: "50px", width: "auto" }}
                            >
                              <img
                                key={tech}
                                alt={tech}
                                src={
                                  process.env.PUBLIC_URL + `/assets/${tech}.png`
                                }
                                height="50px"
                                width="auto"
                              />
                            </Box>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </ModifiedGlassCard>
                </Grow>
              );
            })}
        </Stack>
      </Zoom>
    </Container>
  );
};

export default Portfolio;
