import React, { useState } from "react";
import GlassButton from "../components/GlassButton";
import Container from "../components/Container";
import GlassCard from "../components/GlassCard";
import useWindowSize from "../utils/WindowSize";
import { Avatar, Box, Stack, Typography, Zoom } from "@mui/material";
import ContactButtons from "../components/ContactButtons/ContactButtons";
import { Link } from "react-router-dom";

const Home = () => {
  const [showContactButtons, setShowContactButtons] = useState(false);
  const { windowWidth, windowHeight } = useWindowSize();

  return (
    <Container>
      <Zoom in={true} timeout={500}>
        <GlassCard>
          <Stack
            direction="row"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
          >
            <Avatar
              alt="Santiago Yepes"
              src={process.env.PUBLIC_URL + "/assets/profile-picture.jpeg"}
              sx={{
                width: "50%",
                maxWidth: "18rem",
                height: "auto",
              }}
            />

            {/* text and buttons */}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Typography variant="h4" component="h1">
                Software Developer
              </Typography>

              <Typography variant="h4" component="h1">
                Santiago Yepes
              </Typography>

              {/* buttons */}
              <Stack
                direction="row"
                spacing={1}
                marginTop={3}
                width="100%"
                justifyContent="center"
              >
                <Link
                  to="/about"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <GlassButton>
                    <Typography variant="p" component="p">
                      About
                    </Typography>
                  </GlassButton>
                </Link>

                <Link
                  to="/portfolio"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <GlassButton>
                    <Typography variant="p" component="p">
                      Portfolio
                    </Typography>
                  </GlassButton>
                </Link>

                <GlassButton
                  isPressed={showContactButtons}
                  clickHandler={() =>
                    setShowContactButtons(!showContactButtons)
                  }
                >
                  <Typography variant="p" component="p">
                    Contact
                  </Typography>
                </GlassButton>
              </Stack>

              {/* contact buttons */}
              <ContactButtons show={showContactButtons} />
            </Stack>
          </Stack>
        </GlassCard>
      </Zoom>

      {/* background circles */}
      <Zoom in={true} timeout={450}>
        <Box
          sx={{
            background:
              "linear-gradient(to left bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5))",
            height: " 300px",
            width: "300px",
            position: "absolute",
            borderRadius: "50%",
            top: windowHeight / 2 - 380,
            left: windowWidth / 2 + 150,
          }}
        />
      </Zoom>

      <Zoom in={true} timeout={300}>
        <Box
          sx={{
            background:
              "linear-gradient(to left top, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5))",
            height: " 300px",
            width: "300px",
            position: "absolute",
            borderRadius: "50%",
            top: windowHeight / 2 + 60,
            left: windowWidth / 2 - 450,
          }}
        />
      </Zoom>
    </Container>
  );
};

export default Home;
