import React from "react";
import { Box, Zoom } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import GlassButton from "../GlassButton";

const GithubButton = React.forwardRef((props, ref) => (
  <Box {...props} ref={ref}>
    <Zoom in={props.showButton} timeout={600}>
      {
        <GlassButton
          href={props.href}
          target={props.target} // open link in new window
          sx={{
            minHeight: 0,
            minWidth: 0,
            padding: 1,
            ":hover": {
              color: "white",
              transitionProperty: "all",
              transitionDuration: "250ms",
              transform: "scale(1.05)",
              backgroundImage:
                "linear-gradient(150deg, #537895 10%, #09203f 74%)",
            },
          }}
        >
          <GitHubIcon />
        </GlassButton>
      }
    </Zoom>
  </Box>
));

export default GithubButton;
