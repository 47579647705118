import { Stack } from "@mui/material";
import React from "react";
import GithubButton from "./GithubButton";
import InstagramButton from "./InstagramButton";
import LinkedInButton from "./LinkedInButton";
import MailButton from "./MailButton";

const ContactButtons = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      width="100%"
      marginTop={1}
      visibility={props.show ? "visible" : "hidden"}
    >
      <LinkedInButton
        href="https://www.linkedin.com/in/syepesc/"
        // target="_blank"
        showButton={props.show}
      />
      <GithubButton
        href="https://github.com/syepesc"
        // target="_blank"
        showButton={props.show}
      />
      <MailButton
        href="mailto:santiagoyepescarrera@gmail.com"
        showButton={props.show}
      />
      <InstagramButton
        href="https://www.instagram.com/syepesc/"
        // target="_blank"
        showButton={props.show}
      />
    </Stack>
  );
};

export default ContactButtons;
