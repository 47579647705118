import React from "react";
import { Box, Zoom } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import GlassButton from "../GlassButton";

const MailButton = React.forwardRef((props, ref) => (
  <Box {...props} ref={ref}>
    <Zoom in={props.showButton} timeout={400}>
      {
        <GlassButton
          href={props.href}
          target={props.target} // open link in new window
          sx={{
            minHeight: 0,
            minWidth: 0,
            padding: 1,
            ":hover": {
              color: "white",
              transitionProperty: "all",
              transitionDuration: "250ms",
              transform: "scale(1.05)",
              backgroundImage:
                "linear-gradient(315deg, #06bcfb 0%, #4884ee 74%)",
            },
          }}
        >
          <MailOutlineIcon />
        </GlassButton>
      }
    </Zoom>
  </Box>
));

export default MailButton;
