import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import GlassCard from "../components/GlassCard";
import { Stack, Typography } from "@mui/material";
import GlassButton from "../components/GlassButton";
import HomeIcon from "@mui/icons-material/Home";

function Notfound() {
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, countDown * 1000);

    const timer = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <Container>
      <GlassCard>
        <Stack direction="column" spacing={3} alignItems="center">
          <Typography variant="h2" textAlign="center">
            404 Not Found
          </Typography>

          <Typography variant="h5" textAlign="center" marginBottom={3}>
            Redirect in {countDown} seconds
          </Typography>

          <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
            <GlassButton>
              <HomeIcon />
            </GlassButton>
          </Link>
        </Stack>
      </GlassCard>
    </Container>
  );
}

export default Notfound;
